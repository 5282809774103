.logo {
    display: block;
    width: 160px;
    height: 45px;
    object-fit: contain;
    margin-right: 300px;
}

@media screen and (max-width:475px) {

    .logo {
        width: 80px;
        margin-right: 20px;
    }

}